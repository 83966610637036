.cover-page{
    
    background-image:url("./images/cover.jpg");
    min-height: 100%;

    background-size:100%;
    background-repeat: no-repeat;
    background-position: center;
    
    margin-bottom: 50px;
   
    
    background-attachment: scroll;
    position: relative;
   
    background-size: cover;
    
    
}

.timeline{
    margin: auto;
    max-width: 900px;
    height: 1100px;
}


.title{
    /* min(max(MIN, FLUID), MAX); */
    font-size: min(max(40px, 5vw), 120px);
    text-align:center;
    
}

h1{
    color: white;
    /* -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color:rgb(204, 201, 201); */
}

.card{
    background-image: url("./images/card.png");
    background-repeat: no-repeat;
    background-position: center;
}